import { send_eventApi } from '@/utils/common.js'
import { mapGetters } from 'vuex'
export default {
  computed:{
    ...mapGetters({
      userInfo: 'userInfo'
    })
  },
  methods: {
    /**
     * 升级会员
     * 2021-12-2Equity3 10:28:32
     * @author SnowRock
     * @param id
     * @param type
     */
    handleUpMember(id, type) {
      const obj = {
        user_name: this.userInfo.name,
        user_id: this.userInfo.user_id
      }
      send_eventApi('e_upgrade', obj)
      this.$router.push({
        path: '/package',
        query: {
          order_id: id,
          type
        }
      })
    }
  }
}
